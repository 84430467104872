import $ from 'jquery';
import Scrollbar from 'smooth-scrollbar';

'use strict';
export default class Header {
  constructor() {
    const $menu = $('.j-menu');
    $menu.on('click',(e)=>{
      e.preventDefault();
      $('body').addClass('openMenu');
    });
  
    const $closeMenu = $('.j-closeMenu');
    $closeMenu.on('click',(e)=>{
      e.preventDefault();
      $('body').removeClass('openMenu');
    });
    //
    Scrollbar.init(document.querySelector('#menu'));
  }
}
