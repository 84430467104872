import $ from 'jquery';
'use strict';

export default class Loginform {
  constructor() {
    const $input = $(".form__input");
    $input.focus(function(e){
      let current = e.currentTarget,
          value = $(current).val();
        $(current).addClass('hasValue');
    })
    $input.focusout(function(e){
      let current = e.currentTarget,
          value = $(current).val();
      if(value){
        $(current).addClass('hasValue')
      }else{
        $(current).removeClass('hasValue')
      }
    })

    const $toggleShow = $('.j-toggleShow');
    $toggleShow.on('click',(e)=>{
      e.preventDefault();
      let current = e.currentTarget,
          input = $(current).siblings('input'),
          inputType = $(input).attr('type');
        $(current).toggleClass('visible');
        if(inputType==='password'){
          $(input).attr('type','text')
        }else if(inputType ==='text'){
          $(input).attr('type','password')
        }
    })
    for(let i=0;i<$input.length;i++){
      if($input.eq(i).val()!==''){
        $input.eq(i).addClass('hasValue')
      }
    }
  }
 
}
