'use strict';
import $ from 'jquery';

import Header from '../_modules/header/header'
import LoginForm from '../_modules/loginform/loginForm'

$(() => {
  new Header();
  new LoginForm();
});
